#company-form {
  background-image: url("../../../assets/background-formcompany.svg");
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #d55707;
}

.dadosEmpresa .dropdown-check-list ul.items {
  width: 133px;
}

.dadosEmpresa .dropdown-check-list .anchor {
  width: 133px;
}
input,
textarea {
  color: #008037;
}

#company-form .caracteres {
  font-size: 0.9rem;
  font-weight: 600;
  font-style: italic;
  color: #008037;
}

.questions {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 0px 8px 0px;
}

#company-form textarea {
  width: 80%;
  height: 165px;
  border-radius: 24.5px;
  background: #d9d9d9;
  border-style: none;
  font-size: 1.125rem;
  padding-left: 14px;
  padding-top: 10px;
  margin-top: 5px;
}

.inputs-escolha {
  margin-bottom: 14px;
}

.termoUso a {
  color: #d55707;
  margin: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  font-style: italic;
  cursor: pointer;
}
.termoUso a:hover {
  color: #008037;
  color: -webkit-link #008037;
  text-decoration: underline;
}
.termoUso p {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 0px 8px 0px;
}

input#PoliticaPrivacidade {
  content: "";
  font-size: 1.2em;
  width: 1.2em;
  height: 1.2em;
}

@media screen and (max-width: 768px) {
  #company-form textarea {
    width: 90%;
  }
}
